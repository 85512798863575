import styled, { css } from 'styled-components';
import MizaConstants from '../../support/miza-constants';
import MizaEnums from '../../support/miza-enums';
import ColorHelper from '../../helpers/color-helper';

type ButtonType = typeof MizaEnums.ButtonType[keyof typeof MizaEnums.ButtonType];

type ButtonContainerProps = {
  variant: ButtonType;
  width?: string;
  height?: string;
};

const Primary = css`
  padding: 10px 15px;
  color: ${MizaConstants.Color.TYPEFACE_1};
  background-color: ${MizaConstants.Color.PRIMARY};
  border-radius: ${MizaConstants.BORDER_RADIUS};
  &:hover {
    background-color: ${MizaConstants.Color.ACCENT_1};
    box-shadow: ${MizaConstants.Color.BOX_SHADOW_12};
  }
  &:disabled {
    background-color: ${ColorHelper.applyOpacity(MizaConstants.Color.PRIMARY, 0.5)};
  }
  &:disabled:hover {
    box-shadow: none;
  }
`;

const Delete = css`
  padding: 10px 15px;
  color: ${MizaConstants.Color.TYPEFACE_1};
  background-color: ${MizaConstants.Color.ACCENT_4};
  border-radius: ${MizaConstants.BORDER_RADIUS};
  &:not(:disabled):hover {
    box-shadow: ${MizaConstants.Color.BOX_SHADOW_16};
    background-color: ${MizaConstants.Color.ACCENT_4};
  }
  &:disabled {
    background-color: ${ColorHelper.applyOpacity(MizaConstants.Color.ACCENT_4, 0.5)};
  }
`;

const Tertiary = css`
  color: ${MizaConstants.Color.PRIMARY};
  &:hover {
    color: ${MizaConstants.Color.ACCENT_1};
  }
  &:disabled {
    color: ${ColorHelper.applyOpacity(MizaConstants.Color.PRIMARY, 0.5)};
  }
`;

const Cancel = css`
  color: ${MizaConstants.Color.TYPEFACE_5};
  &:hover {
    color: ${MizaConstants.Color.TYPEFACE_6};
  }
`;

export const ButtonComponent = styled.button<ButtonContainerProps>`
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  font-family: inherit;
  background-color: transparent;
  ${(props: ButtonContainerProps) => props.width && 'width:' + props.width};
  ${(props: ButtonContainerProps) => props.height && 'height:' + props.height};
  ${(props: ButtonContainerProps) => props.variant === MizaEnums.ButtonType.Primary && Primary};
  ${(props: ButtonContainerProps) => props.variant === MizaEnums.ButtonType.Delete && Delete};
  ${(props: ButtonContainerProps) => props.variant === MizaEnums.ButtonType.Tertiary && Tertiary};
  ${(props: ButtonContainerProps) => props.variant === MizaEnums.ButtonType.Cancel && Cancel};
  &:disabled {
    cursor: default;
  }
  + button {
    margin-left: 20px;
  }
`;
