import styled from 'styled-components';
import MizaConstants from '../../support/miza-constants';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  margin: 4% auto auto;
`;

export const Logo = styled.img`
  margin: 50px auto;
  width: 100px;
  height: 100px;
`;

export const WelcomeMessage = styled.div`
  font-size: 18px;
  color: ${MizaConstants.Color.TYPEFACE_4};
  text-align: center;
  margin-bottom: 30px;
`;

export const PasswordTemplate = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: ${MizaConstants.Color.PRIMARY};
  cursor: pointer;
  user-select: none;
  padding-left: 10px;
`;
