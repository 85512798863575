import APIRequestHelper from '../helpers/api-request-helper';

const UserService = {
  async getUser(id: number) {
    return APIRequestHelper.sendRequest('GET', `admin/users/${id}`);
  },
  async getUserGridDatasource() {
    return APIRequestHelper.getDataManager('POST', 'admin/users', false);
  },
  async getGridAccountDatasource() {
    return APIRequestHelper.getDataManager('POST', 'admin/grid_accounts');
  },
};

export default UserService;
