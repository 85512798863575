import React from 'react';
import { LabelContainer } from './Label.styled';

type LabelProps = {
  disabled?: boolean;
  required?: boolean;
  children: React.ReactNode;
  noBottomSpace?: boolean;
};

const Label = (props: LabelProps) => {
  const { disabled = false, required, children, noBottomSpace = false } = props;

  return (
    <LabelContainer disabled={disabled} className="n-ellipsis" noBottomSpace={noBottomSpace} data-testid="label-container">
      {children}
      {required && '*'}
    </LabelContainer>
  );
};

export default Label;
