namespace MizaEnums {
  export enum LoadingFormat {
    Circle,
    Rectangle,
  }
  export enum ButtonType {
    Primary = 1,
    Delete = 2,
    Tertiary = 3,
    Cancel = 4,
  }
  export enum GridCustomizationType {
    None = 0,
    Users = 1
  }
  export enum SubscriptionPlan {
    Free = 0,
    Premium = 1,
    Elite = 2
  }
  export enum SubscriptionPeriod {
    Monthly = 0,
    Yearly = 1
  }
  export enum UserStatus {
    Pending = 0,
    Active = 1, 
    Closed = 2
  }
  export enum TransactionType {
    Expense = 0,
    Income = 1
  }
  export enum BudgetType {
    DebtRepayment = 0,
    Housing = 1,
    Communications = 2,
    Food = 3,
    Insurance = 4,
    Transportation = 5,
    Childcare = 6,
    Education = 7,
    Entertainment = 8,
    PersonalCare = 9,
    Clothing = 10,
    Medical = 11,
    Pets = 12,
    Fees = 13,
    GiftsDonations = 14,
    Other = 15
  }
  export enum AccountType {
    Checking = 0,
    Saving = 1,
    Cash = 2,
    CreditCard = 3,
    LineOfCredit = 4,
    Loan = 5
  }
  export enum RepeatPeriod { 
    Day = 0,
    Week = 1,
    Month = 2,
    Year = 3
  }
  export enum RepeatEndType {
    None = 0,
    Date = 1,
    Count =2
  }

  export enum ErrorCode {
    None = 0,
    ServerError = 1,
    AnotherActiveTrialExists = 2,
    PeriodBetweenTrialsTooShort = 3,
    ActivePaidSubscriptionExists = 4,
    CustomersCountOverMaximum = 5,
    CompanyRequired = 6,
    NotAllowed = 7,
    SubscriptionNotFound = 8,
    TrialOnFree = 9,
    TrialDaysOverMaximum = 10,
  }
}

export default MizaEnums;
