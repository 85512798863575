import { DateRangePicker } from '@syncfusion/ej2-react-calendars';
import MizaConstants from '../../support/miza-constants';
import moment from 'moment';

const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
const startOfThisMonth = moment().startOf('month').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
const endOfThisMonth = moment().endOf('month').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
const sevenDaysAgo = moment().subtract(7, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
const startOfLastMonth = moment().subtract(1, 'months').startOf('month').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
const endOfLastMonth = moment().subtract(1, 'months').endOf('month').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
const startOfLastThreeMonths = moment()
  .subtract(3, 'months')
  .startOf('month')
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .toDate();
const endOfLastThreeMonths = moment()
  .subtract(1, 'months')
  .startOf('month')
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .toDate();

const updateColumnHeaderDate = (gridRef: any, field: string, startDate: Date, endDate: Date) => {
  const columnHeaderDateElement = gridRef.current?.element?.querySelector(`.n-date-column-header-${field} .n-date-column-header-date`);

  if (columnHeaderDateElement) {
    const formattedStartDate = moment(startDate).format(MizaConstants.DateFormat.MOMENT_DEFAULT);
    const formattedEndDate = moment(endDate).format(MizaConstants.DateFormat.MOMENT_DEFAULT);
    columnHeaderDateElement.innerHTML = `${formattedStartDate} - ${formattedEndDate}`;
  }
};

const getDateColumnFilter = (gridRef: any, dateFields: any) => ({
  ui: {
    create: (args: any) => {
      args.dialogObj.element.classList.add('n-date-column-filter-popup');

      const flValInput = document.createElement('input');
      flValInput.classList.add('flm-input');
      args.target.appendChild(flValInput);

      const initialStartDate: Date = gridRef.current?.filterSettings?.columns?.find(
        (column: any) => column.field === args.column.field && !column.isEndDateRange
      )?.value as Date;

      const initialEndDate: Date = gridRef.current?.filterSettings?.columns?.find(
        (column: any) => column.field === args.column.field && column?.isEndDateRange
      )?.value as Date;

      const dateRangePicker = new DateRangePicker({
        startDate: initialStartDate,
        endDate: initialEndDate,
        format: MizaConstants.DateFormat.SYNCFUSION_DEFAULT,
        maxDays: 365,
        presets: [
          {
            label: 'Today',
            start: today,
            end: today,
          },
          {
            label: 'This month',
            start: startOfThisMonth,
            end: endOfThisMonth,
          },
          {
            label: 'Last 7 days',
            start: sevenDaysAgo,
            end: today,
          },
          {
            label: 'Last month',
            start: startOfLastMonth,
            end: endOfLastMonth,
          },
          {
            label: 'Last 3 months',
            start: startOfLastThreeMonths,
            end: endOfLastThreeMonths,
          },
        ],
        change(changeArgs: any) {
          if (changeArgs && changeArgs.value) {
            const startDate = changeArgs.value[0];
            const endDate = changeArgs.value[1];

            dateFields.current[args.column.field] = {
              startDate,
              endDate,
            };

            gridRef.current?.filterByColumn(args.column.field, 'greaterthanorequal', startDate);
            updateColumnHeaderDate(gridRef, args.column.field, startDate, endDate);
          }
        },
      });

      dateRangePicker.appendTo(flValInput);
    },

    read: (args: any) => {
      const value = args.element?.ej2_instances?.[0]?.startDate;

      if (value) {
        gridRef.current?.filterByColumn(args.column.field, 'greaterthanorequal', value);
      }
    },
    // Do not remove empty write function, it is required
    write: () => {}, // NOSONAR
  },
});

export { getDateColumnFilter };
