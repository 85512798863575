import React, { MouseEvent } from 'react';
import MizaConstants from '../../support/miza-constants';
import { IconContainer } from './Icon.styled';

type IconSize = typeof MizaConstants.IconSize[keyof typeof MizaConstants.IconSize];

type IconProps = {
  className?: string;
  disabled?: boolean;
  icon: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  size?: IconSize;
  showHoverEffect?: boolean;
  title?: string;
  color?: string;
  hoverColor?: string;
};

const Icon = (props: IconProps) => {
  const {
    className = '',
    icon,
    size = MizaConstants.IconSize.REGULAR,
    onClick,
    disabled = false,
    showHoverEffect = false,
    title,
    color = MizaConstants.Color.TYPEFACE_4,
    hoverColor = MizaConstants.Color.TYPEFACE_5,
  } = props;

  return (
    <IconContainer
      className={`material-icons-outlined ${className}`}
      size={size}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      showHoverEffect={showHoverEffect}
      title={title}
      data-testid={`icon-${icon}`}
      color={color}
      hoverColor={hoverColor}
    >
      {icon}
    </IconContainer>
  );
};

export default Icon;
