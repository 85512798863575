import React, { useState, useEffect } from 'react';
import { DataManager } from '@syncfusion/ej2-data';
import { UserService } from '../../services';
import MizaEnums from '../../support/miza-enums';
import MizaConstants from '../../support/miza-constants';
import { Grid } from '../../components';
import { useNavigate } from 'react-router-dom';
import { RecordClickEventArgs, SortSettingsModel } from '@syncfusion/ej2-react-grids';
import { MainContainer } from '../../index.styled';

const subscriptionPlanStrings: { [key: string]: string } = {
  [MizaEnums.SubscriptionPlan.Free]: 'Free',
  [MizaEnums.SubscriptionPlan.Premium]: 'Premium',
  [MizaEnums.SubscriptionPlan.Elite]: 'Elite'
};

const userStatusStrings: { [key: string]: string } = {
  [MizaEnums.UserStatus.Pending]: 'Pending',
  [MizaEnums.UserStatus.Active]: 'Active',
  [MizaEnums.UserStatus.Closed]: 'Closed',
};

const getSubscriptionPlanEnumOptions = () =>
  Object.keys(subscriptionPlanStrings).map((key) => ({
    label: subscriptionPlanStrings[key],
    value: parseInt(key),
  }));

  const getUserStatusEnumOptions = () =>
  Object.keys(userStatusStrings).map((key) => ({
    label: userStatusStrings[key],
    value: parseInt(key),
  }));

const subscriptionPlanValueAccessor = (field: string, data: any) => subscriptionPlanStrings[data[field]];
const userStatusValueAccessor = (field: string, data: any) => userStatusStrings[data[field]];

const GridAccountPage = () => {
  const [dataSource, setDataSource] = useState<DataManager | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    async function init() {
      const dataManager = await UserService.getGridAccountDatasource();
      setDataSource(dataManager);
    }

    init();
  }, []);

  const onRecordClick = (args: RecordClickEventArgs) => {
    const rowData = args.rowData as { ID?: number } | undefined;
    const userId = rowData?.ID;

    if (userId) {
      navigate(`/user/${userId}`);
    }
  };

  const columns = [
    // { field: 'AccountNumber', type: 'string', isPrimaryKey: true, headerText: 'Account number', allowSearch: true },
    { field: 'TeamId', type: 'number', headerText: 'Team Id', visible: false },
    { field: 'UserId', type: 'number', headerText: 'User Id', visible: false },
    { field: 'FullName', type: 'string', headerText: 'Name', allowSearch: true },
    { field: 'Email', type: 'string', headerText: 'Email', allowSearch: true },
    {
      field: 'Status',
      type: 'number',
      headerText: 'Status',
      valueAccessor: userStatusValueAccessor,
      enumFilter: getUserStatusEnumOptions(),
    },
    {
      field: 'CreationDate',
      type: 'date',
      headerText: 'Creation Date',
      format: { type: 'date', format: MizaConstants.DateTimeFormat.SYNCFUSION_ISO },
    },
    {
      field: 'LastActivityDate',
      type: 'date',
      headerText: 'Last Activity Date',
      format: { type: 'date', format: MizaConstants.DateTimeFormat.SYNCFUSION_ISO },
      visible: false
    },
    {
      field: 'Plan',
      type: 'number',
      headerText: 'Plan',
      valueAccessor: subscriptionPlanValueAccessor,
      enumFilter: getSubscriptionPlanEnumOptions(),
    },
    { field: 'AutoRenewing', type: 'boolean', headerText: 'AutoRenewing' },
    { field: 'Source', type: 'string', headerText: 'Source', allowSearch: true },
    { field: 'ProductId', type: 'string', headerText: 'Product ID', allowSearch: true },
    {
      field: 'SubscriptionCreationDate',
      type: 'date',
      headerText: 'Subscription Date',
      format: { type: 'date', format: MizaConstants.DateTimeFormat.SYNCFUSION_ISO },
    },
    {
      field: 'SubscriptionEndDate',
      type: 'date',
      headerText: 'Subscription End Date',
      format: { type: 'date', format: MizaConstants.DateTimeFormat.SYNCFUSION_ISO },
      visible: false
    },
    { field: 'Language', type: 'number', headerText: 'Language' },
    { field: 'Currency', type: 'string', headerText: 'Currency' },
    { field: 'TimeZoneOffset', type: 'number', headerText: 'TimeZone' },
    { field: 'CountryCode', type: 'string', headerText: 'CountryCode' },
    { field: 'Note', type: 'string', headerText: 'Note' },
  ];

  const sortSettings: SortSettingsModel = { columns: [{ field: 'CreationDate', direction: 'Descending' }] };

  return dataSource ? (
    dataSource && <MainContainer>
      <Grid
        dataSource={dataSource}
        columns={columns}
        recordClick={onRecordClick}
        sortSettings={sortSettings}
      />
      </MainContainer>
      //gridCustomizationType={MizaEnums.GridCustomizationType.Users}
  ) : <main></main>
};

export default GridAccountPage;
