import styled from 'styled-components';
import MizaConstants from '../../support/miza-constants';

type LabelProps = {
  disabled: boolean;
  noBottomSpace: boolean;
};

export const LabelContainer = styled.div<LabelProps>`
  font-size: 12px;
  color: ${(props) => (props.disabled ? MizaConstants.Color.TYPEFACE_3 : MizaConstants.Color.TYPEFACE_5)};
  ${(props: LabelProps) => !props.noBottomSpace && 'margin-bottom: 10px;'};
`;
