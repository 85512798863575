import React from 'react';
import { useNavigate, useResolvedPath, useMatch, LinkProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MizaLogo from '../../assets/images/miza_logo.png';
import { metadataActions } from '../../store/metadata/metadata-actions';
import {
  MainNavigationLogout,
  MainNavigationLinkIcon,
  StyledLink,
  MainNavigationContainer,
  MainNavigationLogo,
  MainNavigationSeparator,
} from './MainNavigation.styled';

type MainNavigationLinkProps = LinkProps & {
  icon: string;
};

type MainNavigationProps = {
  logout: (successCallback: Function) => void;
};

const MainNavigationLink = ({ to, title, icon, ...props }: MainNavigationLinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });

  return (
    <StyledLink $isActive={!!match} to={to} {...props}>
      <MainNavigationLinkIcon title={title} icon={icon} />
    </StyledLink>
  );
};

const MainNavigation = (props: MainNavigationProps) => {
  const { logout } = props;
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(() => {
      navigate('/login');
    });
  };

  return (
    <MainNavigationContainer>
      <div>
        <MainNavigationLogo src={MizaLogo} />
        <MainNavigationLink to="/home" title="Home" icon="home" />
        <MainNavigationLink to="/grid_account" title="Accounts" icon="account_circle" />
        <MainNavigationLink to="/grid_transaction" title="Transactions" icon="task" />
      </div>
      <div>
        <MainNavigationSeparator />
        <MainNavigationLogout icon="exit_to_app" title="Exit" onClick={handleLogout} />
      </div>
    </MainNavigationContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    canManageAdminUsers: state.metadata.currentUser.canManageAdminUsers,
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      logout: metadataActions.logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigation);
