import React from 'react';
import ReactDOM from 'react-dom';
import Loading from '../Loading';

const LOADING_PLACEHOLDER_MIN_WIDTH = 50;
const LOADING_PLACEHOLDER_MAX_WIDTH = 200;

const renderRowLoadingState = (renderContainer: HTMLElement, numberOfRows: number, numberOfColumns: number) => {
  const loadingPlaceholders = [...new Array(numberOfRows)].map((row, rowIndex) => (
    <tr key={rowIndex} className="m-grid-loading-row e-row">
      {[...new Array(numberOfColumns)].map((column, columnIndex) => {
        const rand = Math.random(); // NOSONAR
        const randomWidth =
          Math.floor(rand * (LOADING_PLACEHOLDER_MAX_WIDTH - LOADING_PLACEHOLDER_MIN_WIDTH + 1)) + LOADING_PLACEHOLDER_MIN_WIDTH;

        return (
          <td key={columnIndex} className="e-rowcell">
            <Loading width={randomWidth} height={10} />
          </td>
        );
      })}
    </tr>
  ));

  ReactDOM.render(loadingPlaceholders, renderContainer);
};

export { renderRowLoadingState };
