import storeHelper from '../../helpers/store-helper';
import { metadataThunks } from './metadata-thunks';

const metadataActionTypePrefix = 'metadata/';

export const metadataActions = {
  login: storeHelper.createThunk(`${metadataActionTypePrefix}login`, metadataThunks.login),
  logout: storeHelper.createThunk(`${metadataActionTypePrefix}logout`, metadataThunks.logout),
  getLoggedInUserData: storeHelper.createThunk(`${metadataActionTypePrefix}getLoggedInUserData`, metadataThunks.getLoggedInUserData),
};
