import React,{ useEffect, useState } from 'react';
// import logo from './logo.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './App.css';
//import MizaRoutes from './support/MizaRoutes';
import { metadataActions } from './store/metadata/metadata-actions';
import MizaRoutes from './support/MizaRoutes';

type AppProps = {
  isLogged: boolean;
  getLoggedInUserData: (callback?: Function) => void;
};

const App = (props: AppProps) => {
  const { isLogged, getLoggedInUserData } = props;
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    getLoggedInUserData(() => {
      setIsReady(true);
    });
  }, []);

  return <>{isReady && <MizaRoutes isLogged={isLogged} />}</>;
};

const mapStateToProps = (state: any) => ({
  isLogged: state.metadata.currentUser.isLogged,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getLoggedInUserData: metadataActions.getLoggedInUserData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);