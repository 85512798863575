import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import Label from '../Label';
import { TextFieldContainer, TextFieldInput } from './TextField.styled';

type TextFieldProps = {
  autoFocus?: boolean;
  rightTemplate?: JSX.Element;
  className?: string;
  disabled?: boolean;
  error?: string;
  label?: string;
  maxLength?: number;
  onChange?: (e: string) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  placeholder?: string;
  required?: boolean;
  type?: string;
  value?: any;
};

const TextField = (props: TextFieldProps) => {
  const {
    autoFocus = false,
    rightTemplate,
    className,
    disabled = false,
    error,
    label,
    maxLength,
    onChange,
    onKeyPress,
    placeholder,
    required = false,
    type = 'text',
    value,
  } = props;
  const [inputValue, setInputValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState(error);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.currentTarget.value);
    setInputValue(event.currentTarget.value);
    setErrorMessage('');
  };

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      {label && (
        <Label disabled={disabled} required={required}>
          {label}
        </Label>
      )}
      <TextFieldContainer error={errorMessage} disabled={disabled} className={className}>
        <TextFieldInput
          aria-label="text-field-value"
          autoFocus={autoFocus}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder}
          type={type}
          title={errorMessage}
          value={inputValue}
          onChange={handleChange}
          onKeyPress={onKeyPress}
        />
        {rightTemplate}
      </TextFieldContainer>
    </>
  );
};

export default TextField;
