import { createReducer } from '@reduxjs/toolkit';
import { metadataActions } from './metadata-actions';

type MetaDataState = {
  currentUser: {
    isLogged: boolean;
    teamId: null | number
  };
};

const initialMetadataState: MetaDataState = {
  currentUser: {
    isLogged: false,
    teamId: null
  },
};

export const metadataReducer = createReducer(initialMetadataState, (builder) => {
  builder
    .addCase(metadataActions.logout.success, (state: MetaDataState) => {
      state.currentUser = initialMetadataState.currentUser;
    })
    .addCase(metadataActions.getLoggedInUserData.success, (state: MetaDataState, action: any) => {
      const { isLogged, teamId } = action.payload;

      state.currentUser = {
        isLogged: isLogged,
        teamId: teamId ?? initialMetadataState.currentUser.teamId,
      };
    })
    .addCase(metadataActions.login.success, (state: MetaDataState, action: any) => {
      const { token, teamId } = action.payload;

      state.currentUser = {
        isLogged: token !== null && token !== '' && token !== undefined,
        teamId: teamId ?? initialMetadataState.currentUser.teamId,
      };
    })
    .addCase(metadataActions.getLoggedInUserData.error, (state: MetaDataState) => {
      state.currentUser = initialMetadataState.currentUser;
    })
});
