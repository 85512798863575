import React from 'react';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { CheckboxContainer } from './Checkbox.styled';

type CheckboxProps = {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: any) => void;
};

const Checkbox = (props: CheckboxProps) => {
  const { label, checked, disabled, onChange } = props;

  return (
    <CheckboxContainer>
      <CheckBoxComponent label={label} checked={checked} change={onChange} disabled={disabled} />
    </CheckboxContainer>
  );
};

export default Checkbox;
