import APIRequestHelper from '../helpers/api-request-helper';

const KpiService = {
  async get(date: string) {
    const payload = {
      filters: [
        {
          name: 'start_date',
          operator: 'eq',
          value: date,
        },
        {
          name: 'end_date',
          operator: 'eq',
          value: date,
        },
      ],
    };

    return APIRequestHelper.sendRequest('POST', 'admin/kpis', payload);
  },
};

export default KpiService;
