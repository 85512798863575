import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { DataManager } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {
  GridComponent,
  Inject,
  Page,
  Search,
  ToolbarItems,
  Filter,
  Sort,
  FilterSettingsModel,
  ColumnChooser,
  Toolbar,
  ExcelExport,
  Resize,
  PageSettingsModel,
  ColumnsDirective,
  ColumnDirective,
  ColumnModel,
  Reorder,
  SearchSettingsModel,
  RecordClickEventArgs,
  SortSettingsModel,
} from '@syncfusion/ej2-react-grids';
import '@syncfusion/ej2-react-grids/styles/material.css';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import { StyledGrid } from './Grid.styled';
import { renderRowLoadingState } from './rowLoadingState';
import DefaultNoDataTemplate from './DefaultNoDataTemplate';
import { getDateColumnFilter } from './dateColumnFilter';
import { getEnumColumnFilter } from './enumColumnFilter';
import MizaEnum from '../../support/miza-enums';
//import gridCustomizationService from '../../services/grid-customization-service';

type GridColumn = ColumnModel & { allowSearch?: boolean; enumFilter?: { label: string; value: any }[] };

type ToolbarItem = { id?: string } | ToolbarItems;

type MizaColumn = {
  field: string;
  index: number;
  width: number;
  visible: boolean;
};

type MizaData = {
  columns?: MizaColumn[];
  filterSettings?: FilterSettingsModel;
  toolbar?: ToolbarItem[];
  pageSettings?: PageSettingsModel;
  searchSettings?: SearchSettingsModel;
  sortSettings?: SortSettingsModel;
};

type GridProps = {
  dataSource: DataManager | object[];
  columns: GridColumn[];
  filterSettings?: FilterSettingsModel;
  toolbar?: ToolbarItem[];
  pageSettings?: PageSettingsModel;
  searchSettings?: SearchSettingsModel;
  sortSettings?: SortSettingsModel;
  allowPaging?: boolean;
  allowSorting?: boolean;
  allowFiltering?: boolean;
  allowResizing?: boolean;
  allowExcelExport?: boolean;
  allowReordering?: boolean;
  showToolbar?: boolean;
  recordClick?: (args: RecordClickEventArgs) => void;
  noDataTemplate?: JSX.Element;
  gridCustomizationType?: MizaEnum.GridCustomizationType;
};

const DEFAULT_COLUMN_WIDTH = 200;
const MIN_COLUMN_WIDTH = 100;

const defaultFilterSettings: FilterSettingsModel = {
  type: 'Menu',
  ignoreAccent: true,
};

const defaultPageSettings: PageSettingsModel = { pageSize: 10, pageSizes: [10, 15, 20, 50] };

const defaultSearchSettings: SearchSettingsModel = { ignoreCase: true, ignoreAccent: true, operator: 'contains' };

const defaultSortSettings = { columns: [] };

const defaultToolbar: (object | ToolbarItems)[] = [
  { text: 'Clear filters', id: 'm-grid-clear-filters', align: 'Right' },
  { text: 'Reset Layout', id: 'm-grid-reset-customization', align: 'Right' },
  { text: 'Export to Excel', id: 'm-grid-export-excel', align: 'Right', prefixIcon: 'm-grid-export-excel-icon' },
  'ColumnChooser',
  'Search',
];

// Syncfusion Grid needs its container to have an explicit width to properly handle horizontal scrolling
// when the columns does not fit in the grid display, else it overflows the page or there is no scroll bar
const updateGridContainerWidth = (gridContainerElement: HTMLElement | null, gridElement?: HTMLElement) => {
  if (!gridContainerElement) {
    return;
  }

  let originalGridElementStyleDisplay: string = '';

  gridContainerElement.style.width = '';

  if (gridElement) {
    originalGridElementStyleDisplay = gridElement.style.display;
    gridElement.style.display = 'none';
  }

  const gridContainerParentWidth = gridContainerElement.parentElement?.clientWidth as number;
  const gridContainerStyles = getComputedStyle(gridContainerElement.parentElement as HTMLDivElement);
  const newGridContainerWidth =
    gridContainerParentWidth - parseInt(gridContainerStyles.paddingLeft) - parseInt(gridContainerStyles.paddingRight);

  gridContainerElement.style.width = `${newGridContainerWidth}px`;

  if (gridElement) {
    gridElement.style.display = originalGridElementStyleDisplay;
  }
};

const getDateColumnHeaderTemplate = (columnData: any) =>
  `<div class="n-ellipsis n-date-column-header-${columnData.field}">
    ${columnData.headerText}
    <span class="n-date-column-header-date"></span>
  </div>`;

function mapSyncfusionDataToMizaData(syncfusionData: GridComponent | null) {
  const mizaData: MizaData = {};
  mizaData.columns = syncfusionData?.columns?.map((column: any, index) => ({
    field: column.field,
    index,
    type: column.type,
    width: column.width,
    enumFilter: column.enumFilter,
    visible: column.visible,
  }));
  mizaData.filterSettings = {
    columns: syncfusionData?.filterSettings?.columns?.map((column: any) => ({
      field: column.field,
      operator: column.operator,
      predicate: column.predicate,
      value: column.value,
      isEndDateRange: column.isEndDateRange,
    })),
  };

  mizaData.pageSettings = {
    pageSize: syncfusionData?.pageSettings?.pageSize,
  };

  mizaData.sortSettings = {
    columns: syncfusionData?.sortSettings?.columns?.map((column) => ({ field: column.field, direction: column.direction })),
  };

  return mizaData;
}

const Grid = (props: GridProps) => {
  const {
    dataSource,
    columns,
    toolbar = defaultToolbar,
    filterSettings = defaultFilterSettings,
    pageSettings = defaultPageSettings,
    searchSettings = defaultSearchSettings,
    sortSettings = defaultSortSettings,
    allowPaging = true,
    allowSorting = true,
    allowFiltering = true,
    allowResizing = true,
    allowExcelExport = true,
    allowReordering = true,
    showToolbar = true,
    recordClick,
    noDataTemplate = DefaultNoDataTemplate,
    gridCustomizationType = MizaEnum.GridCustomizationType.None,
  } = props;

  const [gridCustomizationLoaded, setGridCustomizationLoaded] = useState<boolean>(false);
  const [persistedProps, setPersistedProps] = useState<any>({});
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const gridRef = useRef<GridComponent | null>(null);
  const gridCustomizationData = useRef<MizaData | null>(null);
  const dateFields = useRef<any>({});

  useEffect(() => {
    async function initializeGrid() {
      // const gridCustomization = await gridCustomizationService.getGridCustomization(gridCustomizationType);

      // if (gridCustomization?.customization) {
      //   gridCustomizationData.current = gridCustomization.customization;
      // }

      setPersistedProps(mapMizaDataToSyncfusion());
      setGridCustomizationLoaded(true);
      showToolbarItemResetLayout(gridCustomizationData?.current != null);
      showToolbarItemClearFilters(
        gridCustomizationData?.current?.filterSettings?.columns?.length != null &&
          gridCustomizationData?.current?.filterSettings?.columns?.length > 0
      );
    }

    const updateGridContainerWidthOnResize = () => {
      updateGridContainerWidth(gridContainerRef.current, gridRef.current?.element);
    };

    updateGridContainerWidthOnResize();
    window.addEventListener('resize', updateGridContainerWidthOnResize);

    initializeGrid();

    return () => {
      window.removeEventListener('resize', updateGridContainerWidthOnResize);
    };
  }, []);

  const getSearchFields = (gridColumns: any) => {
    if (gridColumns) {
      return gridColumns
        .filter((column: any) => (column?.allowSearch ?? false) && (column?.visible ?? true))
        .map((column: any) => column.field);
    }
  };

  const onToolbarClick = (args: ClickEventArgs) => {
    if (args.item.id === 'm-grid-export-excel') {
      gridRef.current?.excelExport();
    }

    if (args.item.id === 'm-grid-reset-customization') {
      resetGridCustomization();
    }

    if (args.item.id === 'm-grid-clear-filters') {
      resetGridFilters();
    }
  };

  const getLocalPersistedProps = () => {
    const local: any = {};

    local.columns = columns
      .filter((column: any) => column?.isAccessible ?? true)
      .map((column) => {
        const columnWidth = column.width ?? DEFAULT_COLUMN_WIDTH;
        const columnData = { minWidth: MIN_COLUMN_WIDTH, width: columnWidth, ...column };

        if (column.type === 'string' && !column.filter) {
          columnData.filter = { operator: 'contains' };
        }

        if (column.enumFilter) {
          columnData.filter = getEnumColumnFilter(column.enumFilter);
        }

        if (column.type === 'date') {
          columnData.filter = getDateColumnFilter(gridRef, dateFields);
          columnData.headerTemplate = getDateColumnHeaderTemplate(columnData);
        }

        return columnData;
      });

    local.filterSettings = {
      ignoreAccent: true,
      type: 'Menu',
      ...filterSettings,
      columns: [...(filterSettings?.columns ?? [])],
    };

    local.pageSettings = {
      pageSize: 10,
      pageSizes: [10, 15, 20, 50],
      ...pageSettings,
    };

    local.searchSettings = {
      ignoreCase: true,
      operator: 'contains',
      ...searchSettings,
      fields: getSearchFields(local.columns),
    };

    local.sortSettings = {
      columns: [],
      ...sortSettings,
    };

    return local;
  };

  const mapMizaDataToSyncfusion = () => {
    const localPersistedProps = getLocalPersistedProps();
    const customization = gridCustomizationData.current;

    if (customization) {
      const mappedPersistedProps: any = {};

      mappedPersistedProps.columns = localPersistedProps.columns
        .map((column: any) => {
          const persistedColumnData = customization?.columns?.find((serverColumn) => serverColumn.field === column.field);
          const columnData = { ...column };

          if (persistedColumnData) {
            columnData.index = persistedColumnData.index;
            columnData.visible = persistedColumnData.visible;
            columnData.width = persistedColumnData.width;
          }

          return columnData;
        })
        .sort((a: any, b: any) => a.index - b.index); // Re-order the columns, required for correct display

      mappedPersistedProps.filterSettings = {
        ...localPersistedProps.filterSettings,
      };

      customization?.filterSettings?.columns?.forEach((column) => {
        const columnIndex = mappedPersistedProps.filterSettings.columns.findIndex(
          (filterColumn: any) => filterColumn.field === column.field && filterColumn.operator === column.operator
        );

        if (columnIndex > -1) {
          mappedPersistedProps.filterSettings.columns[columnIndex] = column;
        } else if (Array.isArray(column.value)) {
          column.value.forEach((currentValue) => {
            mappedPersistedProps.filterSettings.columns.push({
              ...column,
              value: currentValue,
            });
          });
        } else {
          mappedPersistedProps.filterSettings.columns.push(column);
        }
      });

      mappedPersistedProps.pageSettings = {
        ...localPersistedProps.pageSettings,
        ...customization.pageSettings,
      };

      mappedPersistedProps.searchSettings = {
        ...localPersistedProps.searchSettings,
        fields: getSearchFields(mappedPersistedProps.columns),
      };

      mappedPersistedProps.sortSettings = {
        ...localPersistedProps.sortSettings,
        ...customization.sortSettings,
      };

      return mappedPersistedProps;
    }

    return localPersistedProps;
  };

  const resetGridCustomization = async () => {
    // const resetSuccess = await gridCustomizationService.resetGridCustomization(gridCustomizationType);
    // if (resetSuccess) {
    //   updateGridProperties(getLocalPersistedProps());
    //   showToolbarItemResetLayout(false);
    //   showToolbarItemClearFilters(false);
    // }
  };

  const resetGridFilters = async () => {
    updateGridProperties({ filterSettings: { columns: {} } });
    saveGridCustomization();
  };

  const showToolbarItemResetLayout = (show = false) => {
    const toolbarItemResetLayout = gridRef.current?.element?.querySelector('#m-grid-reset-customization')?.parentElement;

    if (!toolbarItemResetLayout) {
      return;
    }

    if (show) {
      toolbarItemResetLayout.classList.remove('n-hidden');
    } else {
      toolbarItemResetLayout.classList.add('n-hidden');
    }
  };

  const showToolbarItemClearFilters = (show = false) => {
    const toolbarItemClearFilters = gridRef.current?.element?.querySelector('#m-grid-clear-filters')?.parentElement;

    if (!toolbarItemClearFilters) {
      return;
    }

    if (show) {
      toolbarItemClearFilters.classList.remove('n-hidden');
    } else {
      toolbarItemClearFilters.classList.add('n-hidden');
    }
  };

  const saveGridCustomization = async () => {
    if (!gridCustomizationType) {
      return;
    }

    const gridCustomization = {
      type: gridCustomizationType,
      customization: mapSyncfusionDataToMizaData(gridRef.current),
    };

    // const responseData = await gridCustomizationService.saveGridCustomization(gridCustomization);
    // showToolbarItemResetLayout(responseData != null);
    // showToolbarItemClearFilters(
    //   responseData?.customization?.filterSettings?.columns != null && responseData?.customization?.filterSettings?.columns?.length !== 0
    // );
  };

  const showLoadingPlaceholders = () => {
    const emptyRowElement = gridRef.current?.element?.querySelector('.e-emptyrow');

    if (emptyRowElement) {
      const numberOfRows = gridCustomizationData.current?.pageSettings?.pageSize ?? (pageSettings.pageSize as number);
      const numberOfColumns =
        gridCustomizationData.current?.columns?.filter((column) => column?.visible ?? true)?.length ??
        columns.filter((column) => column.visible ?? true).length;
      const emptyRowParentElement = emptyRowElement.parentElement;

      if (emptyRowParentElement) {
        renderRowLoadingState(emptyRowParentElement, numberOfRows, numberOfColumns);
      }
    }
  };

  const showNoData = (show: boolean) => {
    const gridContentElement = gridRef.current?.element?.querySelector('.e-gridcontent .e-content');

    if (!gridContentElement) {
      return;
    }

    if (show) {
      const root = gridRef.current?.element?.querySelector('.m-grid-no-data-root');
      if (!root) {
        const noDataRootElement = document.createElement('div');
        noDataRootElement.classList.add('m-grid-no-data-root');
        gridContentElement.prepend(noDataRootElement);
        ReactDOM.render(noDataTemplate, noDataRootElement);
      }
      gridContentElement.classList.add('m-grid-has-no-data');
    } else if (gridContentElement) {
      gridContentElement.classList.remove('m-grid-has-no-data');
    }
  };

  const onCreated = () => {
    showLoadingPlaceholders();
  };

  const onDataBound = () => {
    if (gridRef.current?.currentViewData?.length) {
      showNoData(false);
    } else {
      showNoData(true);
    }
  };

  const updateGridProperties = (properties: any) => {
    const gridInstance = gridRef.current;

    if (gridInstance) {
      gridInstance.setProperties(properties, true);
      gridInstance.freezeRefresh();
      showLoadingPlaceholders();
    }
  };

  const onActionBegin = (args: any) => {
    if (args.requestType !== 'filtering') {
      return;
    }

    if (args.action === 'clearFilter') {
      args.cancel = true;

      const filteredColumns = gridRef.current?.filterSettings?.columns?.filter(
        (column) => column.field !== args.currentFilterColumn?.field
      );

      updateGridProperties({ filterSettings: { columns: filteredColumns } });
    }

    if (args.action !== 'clearFilter') {
      const currentFilteringColumn = gridRef.current?.getColumnByField(args.currentFilteringColumn);

      if (currentFilteringColumn && currentFilteringColumn.type === 'date') {
        const endDateColumnIndex = args.columns.findIndex(
          (column: any) => column.field === args.currentFilteringColumn && column.isEndDateRange
        );

        if (endDateColumnIndex === -1) {
          args.columns.push({
            isEndDateRange: true,
            field: currentFilteringColumn.field,
            operator: 'lessthanorequal',
            predicate: 'and',
            value: dateFields.current[currentFilteringColumn.field]?.endDate,
          });
        } else {
          args.columns[endDateColumnIndex].value = dateFields.current[currentFilteringColumn.field]?.endDate;
        }
      }
    }
    saveGridCustomization();
  };

  const onActionComplete = (args: any) => {
    const isActionComplete = args.name === 'actionComplete' || args.type === 'actionComplete';

    if (
      !gridRef.current ||
      !isActionComplete ||
      (args.requestType !== 'columnstate' &&
        args.requestType !== 'reorder' &&
        args.requestType !== 'sorting' &&
        args.requestType !== 'paging')
    ) {
      return;
    }
    if (args.requestType === 'columnstate') {
      gridRef.current.searchSettings.fields = getSearchFields(gridRef.current.columns);
    }

    saveGridCustomization();
  };

  const services = [
    { module: Toolbar, validator: () => toolbar?.length > 0 ?? false },
    { module: ColumnChooser, validator: () => toolbar?.includes('ColumnChooser') ?? false },
    { module: Search, validator: () => toolbar?.includes('Search') ?? false },
    { module: Page, validator: () => allowPaging },
    { module: Sort, validator: () => allowSorting },
    { module: Reorder, validator: () => allowReordering },
    { module: Resize, validator: () => allowResizing },
    { module: Filter, validator: () => allowFiltering },
    {
      module: ExcelExport,
      validator: () => (allowExcelExport && toolbar?.find((item: any) => item?.id === 'm-grid-export-excel') !== undefined) ?? false,
    },
  ];

  const injectedServices = services.filter((s) => s.validator()).map((s) => s.module);

  return (
    <div ref={gridContainerRef}>
      {gridCustomizationLoaded && (
        <StyledGrid
          ref={gridRef}
          dataSource={dataSource}
          toolbar={showToolbar ? toolbar : null}
          actionBegin={onActionBegin}
          actionComplete={onActionComplete}
          allowPaging={allowPaging}
          allowFiltering={allowFiltering}
          allowSorting={allowSorting}
          allowExcelExport={allowExcelExport}
          allowReordering={allowReordering}
          showColumnChooser={true}
          allowResizing={allowResizing}
          filterSettings={persistedProps.filterSettings}
          pageSettings={persistedProps.pageSettings}
          sortSettings={persistedProps.sortSettings}
          searchSettings={persistedProps.searchSettings}
          toolbarClick={onToolbarClick}
          recordClick={recordClick}
          created={onCreated}
          dataBound={onDataBound}
        >
          <ColumnsDirective>
            {persistedProps.columns.map((column: any, index: any) => (
              <ColumnDirective key={index} {...column} />
            ))}
          </ColumnsDirective>
          <Inject services={injectedServices} />
        </StyledGrid>
      )}
    </div>
  );
};

export default Grid;
