import React, { useState, useEffect } from 'react';
import { DataManager } from '@syncfusion/ej2-data';
import MizaEnums from '../../support/miza-enums';
import MizaConstants from '../../support/miza-constants';
import { Grid } from '../../components';
import { useNavigate } from 'react-router-dom';
import { RecordClickEventArgs, SortSettingsModel } from '@syncfusion/ej2-react-grids';
import { MainContainer } from '../../index.styled';
import TransactionService from '../../services/transaction-service';

const transactionTypeStrings: { [key: string]: string } = {
  [MizaEnums.TransactionType.Expense]: 'Expense',
  [MizaEnums.TransactionType.Income]: 'Income'
};

const accountTypeStrings: { [key: string]: string } = {
  [MizaEnums.AccountType.Checking]: 'Checking',
  [MizaEnums.AccountType.Saving]: 'Saving',
  [MizaEnums.AccountType.Cash]: 'Cash',
  [MizaEnums.AccountType.CreditCard]: 'CreditCard',
  [MizaEnums.AccountType.LineOfCredit]: 'LineOfCredit',
  [MizaEnums.AccountType.Loan]: 'Loan',
};

const budgetTypeStrings: { [key: string]: string } = {
  [MizaEnums.BudgetType.DebtRepayment]: 'DebtRepayment',
  [MizaEnums.BudgetType.Housing]: 'Housing',
  [MizaEnums.BudgetType.Communications]: 'Communications',
  [MizaEnums.BudgetType.Food]: 'Food',
  [MizaEnums.BudgetType.Insurance]: 'Insurance',
  [MizaEnums.BudgetType.Transportation]: 'Transportation',
  [MizaEnums.BudgetType.Childcare]: 'Childcare',
  [MizaEnums.BudgetType.Education]: 'Education',
  [MizaEnums.BudgetType.Entertainment]: 'Entertainment',
  [MizaEnums.BudgetType.PersonalCare]: 'PersonalCare',
  [MizaEnums.BudgetType.Clothing]: 'Clothing',
  [MizaEnums.BudgetType.Medical]: 'Medical',
  [MizaEnums.BudgetType.Pets]: 'Pets',
  [MizaEnums.BudgetType.Fees]: 'Fees',
  [MizaEnums.BudgetType.GiftsDonations]: 'GiftsDonations',
  [MizaEnums.BudgetType.Other]: 'Other',
};

const repeatPeriodStrings: { [key: string]: string } = {
  [MizaEnums.RepeatPeriod.Day]: 'Day',
  [MizaEnums.RepeatPeriod.Week]: 'Week',
  [MizaEnums.RepeatPeriod.Month]: 'Month',
  [MizaEnums.RepeatPeriod.Year]: 'Year',
};

const repeatEndTypeStrings: { [key: string]: string } = {
  [MizaEnums.RepeatEndType.None]: 'None',
  [MizaEnums.RepeatEndType.Date]: 'Date',
  [MizaEnums.RepeatEndType.Count]: 'Count',
};

const getTransactionTypeEnumOptions = () =>
  Object.keys(transactionTypeStrings).map((key) => ({
    label: transactionTypeStrings[key],
    value: parseInt(key),
  }));

const getBudgetTypeEnumOptions = () =>
Object.keys(budgetTypeStrings).map((key) => ({
  label: budgetTypeStrings[key],
  value: parseInt(key),
}));

  const getAccountTypeEnumOptions = () =>
  Object.keys(accountTypeStrings).map((key) => ({
    label: accountTypeStrings[key],
    value: parseInt(key),
  }));

  const getRepeatPeriodEnumOptions = () =>
  Object.keys(repeatPeriodStrings).map((key) => ({
    label: repeatPeriodStrings[key],
    value: parseInt(key),
  }));

  const getRepeatEndTypeEnumOptions = () =>
  Object.keys(repeatEndTypeStrings).map((key) => ({
    label: repeatEndTypeStrings[key],
    value: parseInt(key),
  }));

const transactionTypeValueAccessor = (field: string, data: any) => transactionTypeStrings[data[field]];
const budgetTypeValueAccessor = (field: string, data: any) => budgetTypeStrings[data[field]];
const accountTypeValueAccessor = (field: string, data: any) => accountTypeStrings[data[field]];
const repeatPeriodValueAccessor = (field: string, data: any) => repeatPeriodStrings[data[field]];
const repeatEndTypeValueAccessor = (field: string, data: any) => repeatEndTypeStrings[data[field]];

const GridTransactionPage = () => {
  const [dataSource, setDataSource] = useState<DataManager | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    async function init() {
      const dataManager = await TransactionService.getGridTransactionDatasource();
      setDataSource(dataManager);
    }

    init();
  }, []);

  const onRecordClick = (args: RecordClickEventArgs) => {
    const rowData = args.rowData as { ID?: number } | undefined;
    const userId = rowData?.ID;

    if (userId) {
      navigate(`/user/${userId}`);
    }
  };

  const columns = [
    // { field: 'AccountNumber', type: 'string', isPrimaryKey: true, headerText: 'Account number', allowSearch: true },
    { field: 'Id', type: 'number', headerText: 'Id', visible: false },
    { field: 'TeamId', type: 'number', headerText: 'Team Id' },
    { field: 'Amount', type: 'number', headerText: 'Amount' },
    {
      field: 'Date',
      type: 'date',
      headerText: 'Date',
      format: { type: 'date', format: MizaConstants.DateFormat.SYNCFUSION_ISO },
    },
    {
      field: 'TransactionType',
      type: 'number',
      headerText: 'Transaction Type',
      valueAccessor: transactionTypeValueAccessor,
      enumFilter: getTransactionTypeEnumOptions(),
    },
    { field: 'Note', type: 'string', headerText: 'Note', allowSearch: true },
    {
      field: 'BudgetType',
      type: 'number',
      headerText: 'Budget Type',
      valueAccessor: budgetTypeValueAccessor,
      enumFilter: getBudgetTypeEnumOptions(),
    },
    { field: 'CategoryCode', type: 'string', headerText: 'Category Code', allowSearch: true },
    {
      field: 'PrimaryAccountType',
      type: 'number',
      headerText: 'Account Type',
      valueAccessor: accountTypeValueAccessor,
      enumFilter: getAccountTypeEnumOptions(),
      visible: false
    },
    { field: 'RecurringTransactionId', type: 'number', headerText: 'Recurring Id', visible: false },
    { field: 'Frequency', type: 'number', headerText: 'Frequency' },
    {
      field: 'Period',
      type: 'number',
      headerText: 'Period',
      valueAccessor: repeatPeriodValueAccessor,
      enumFilter: getRepeatPeriodEnumOptions(),
      visible: false
    },
    {
      field: 'EndType',
      type: 'number',
      headerText: 'End Type',
      valueAccessor: repeatEndTypeValueAccessor,
      enumFilter: getRepeatEndTypeEnumOptions(),
      visible: false
    },
    {
      field: 'StartDate',
      type: 'date',
      headerText: 'Start Date',
      format: { type: 'date', format: MizaConstants.DateFormat.SYNCFUSION_ISO },
      visible: false
    },
    {
      field: 'EndDate',
      type: 'date',
      headerText: 'End Date',
      format: { type: 'date', format: MizaConstants.DateFormat.SYNCFUSION_ISO },
      visible: false
    },
    {
      field: 'LastUsedDate',
      type: 'date',
      headerText: 'Last Used Date',
      format: { type: 'date', format: MizaConstants.DateFormat.SYNCFUSION_ISO },
      visible: false
    },
    { field: 'EndCount', type: 'number', headerText: 'End count', visible: false },
    { field: 'LastUsedCount', type: 'number', headerText: 'Last Used Count', visible: false },
  ];

  const sortSettings: SortSettingsModel = { columns: [{ field: 'Id', direction: 'Descending' }] };

  return dataSource ? (
    dataSource && <MainContainer>
      <Grid
        dataSource={dataSource}
        columns={columns}
        recordClick={onRecordClick}
        sortSettings={sortSettings}
      />
      </MainContainer>
      //gridCustomizationType={MizaEnums.GridCustomizationType.Users}
  ) : <main></main>
};

export default GridTransactionPage;
