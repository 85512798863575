import styled, { keyframes } from 'styled-components';
import MizaConstants from '../../support/miza-constants';

const toastMessageAnimateIn = keyframes`
  from { opacity: 0; transform: translateY(100%); }
  to { opacity: 1; transform: translateY(0); }
`;

export const ToastMessageQueueContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 300;
`;

export const ToastMessageContainer = styled.div`
  padding: 20px;
  border-radius: ${MizaConstants.BORDER_RADIUS};
  color: ${MizaConstants.Color.TYPEFACE_1};
  background: ${MizaConstants.Color.SURFACE_5};
  min-width: 350px;
  margin: 20px auto 0 auto;
  animation: ${toastMessageAnimateIn} 280ms;
`;
