import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Link, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import { mizaStore } from './store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { MizaBaseStyles, SyncfusionStyles } from './index.styled';

ReactDOM.render(
  <React.StrictMode>
    <MizaBaseStyles />
    <SyncfusionStyles />
    <Provider store={mizaStore}>
    <Router>
      <App />
    </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('m-root')
);

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
