import { useEffect, useState } from "react";
import Card from "../../components/Card";
import dateTimeHelper from "../../helpers/date-time-helper";
import KpiService from "../../services/kpi-service";
import { Cards, Title, Content } from "./HomePage.styled";

export default function HomePage() {
  const [notifications, setNotifications] = useState<number>();
  const [notificationsLastTime, setNotificationsLastTime] = useState<Date|null>();
  const [monthlySavingNotifications, setMonthlySavingNotifications] = useState<number>();
  const [monthlySavingNotificationsLastTime, setMonthlySavingNotificationsLastTime] = useState<Date|null>();
  const [paycheckNotifications, setPaycheckNotifications] = useState<number>();
  const [paycheckNotificationsLastTime, setPaycheckNotificationsLastTime] = useState<Date|null>();
  const [inactivityNotifications, setInactivityNotifications] = useState<number>();
  const [inactivityNotificationsLastTime, setInactivityNotificationsLastTime] = useState<Date|null>();
  const [recurringTransactions, setRecurringTransactions] = useState<number>();
  const [recurringTransactionsLastTime, setRecurringTransactionsLastTime] = useState<Date|null>();
  const [appleMonthlyValid, setAppleMonthlyValid] = useState<number>();
  const [appleYearlyValid, setAppleYearlyValid] = useState<number>();
  const [appleMonthlyExpired, setAppleMonthlyExpired] = useState<number>();
  const [appleYearlyExpired, setAppleYearlyExpired] = useState<number>();
  const [googleMonthlyValid, setGoogleMonthlyValid] = useState<number>();
  const [googleYearlyValid, setGoogleYearlyValid] = useState<number>();
  const [googleMonthlyExpired, setGoogleMonthlyExpired] = useState<number>();
  const [googleYearlyExpired, setGoogleYearlyExpired] = useState<number>();
  const [appleMonthlyEliteValid, setAppleMonthlyEliteValid] = useState<number>();
  const [appleYearlyEliteValid, setAppleYearlyEliteValid] = useState<number>();
  const [appleMonthlyEliteExpired, setAppleMonthlyEliteExpired] = useState<number>();
  const [appleYearlyEliteExpired, setAppleYearlyEliteExpired] = useState<number>();
  const [googleMonthlyEliteValid, setGoogleMonthlyEliteValid] = useState<number>();
  const [googleYearlyEliteValid, setGoogleYearlyEliteValid] = useState<number>();
  const [googleMonthlyEliteExpired, setGoogleMonthlyEliteExpired] = useState<number>();
  const [googleYearlyEliteExpired, setGoogleYearlyEliteExpired] = useState<number>();
  const [plaidWebhoos, setPlaidWebhoos] = useState<number>();
  const [closedTeams, setClosedTeams] = useState<number>();

  function diplayContent (title: string, content: number|undefined, isNegative?: boolean) {
    return <div>
              <Content $isPositive={isNegative}>{content}</Content>
              <Title>{title}</Title>
            </div>
  };

  useEffect(() => {
    async function init() {
      const response = await KpiService.get(dateTimeHelper.today());
      const kpiData = response.data[0];
      setNotifications(kpiData.notifications),
      setNotificationsLastTime(kpiData.notifications_last_time),
      setMonthlySavingNotifications(kpiData.monthly_saving_notifications),
      setMonthlySavingNotificationsLastTime(kpiData.monthly_saving_notifications_last_time),
      setPaycheckNotifications(kpiData.paycheck_notifications),
      setPaycheckNotificationsLastTime(kpiData.paycheck_notifications_last_time),
      setInactivityNotifications(kpiData.inactivity_notifications),
      setInactivityNotificationsLastTime(kpiData.inactivity_notifications_last_time),
      setRecurringTransactions(kpiData.recurring_transactions),
      setRecurringTransactionsLastTime(kpiData.recurring_transactions_last_time),
      setAppleMonthlyValid(kpiData.apple_monthly_valid),
      setAppleYearlyValid(kpiData.apple_yearly_valid)
      setAppleMonthlyExpired(kpiData.apple_monthly_expired),
      setAppleYearlyExpired(kpiData.apple_yearly_expired)
      setGoogleMonthlyValid(kpiData.google_monthly_valid),
      setGoogleYearlyValid(kpiData.google_yearly_valid)
      setGoogleMonthlyExpired(kpiData.google_monthly_expired),
      setGoogleYearlyExpired(kpiData.google_yearly_expired)
      setAppleMonthlyEliteValid(kpiData.apple_monthly_elite_valid),
      setAppleYearlyEliteValid(kpiData.apple_yearly_elite_valid)
      setAppleMonthlyEliteExpired(kpiData.apple_monthly_elite_expired),
      setAppleYearlyEliteExpired(kpiData.apple_yearly_elite_expired)
      setGoogleMonthlyEliteValid(kpiData.google_monthly_elite_valid),
      setGoogleYearlyEliteValid(kpiData.google_yearly_elite_valid)
      setGoogleMonthlyEliteExpired(kpiData.google_monthly_elite_expired),
      setGoogleYearlyEliteExpired(kpiData.google_yearly_elite_expired)
      setPlaidWebhoos(kpiData.plaid_webhooks),
      setClosedTeams(kpiData.closed_teams)
    }

    init();
  }, []);

  return (
    <main style={{ padding: "1rem 0", overflow: 'auto'}}>
      <h1>Home page</h1>
      <div>{dateTimeHelper.today()}</div>
      <div>
      <Cards>
        <Card content={diplayContent('Apple Monthly Elite Valid', appleMonthlyEliteValid, true)} width="200px" backgroundColor="#FFF59D"></Card>
        <Card content={diplayContent('Apple Yearly Elite Valid', appleYearlyEliteValid, true)} width="200px" backgroundColor="#FFF59D"></Card>
        <Card content={diplayContent('Google Monthly Elite Valid', googleMonthlyEliteValid, true)} width="200px" backgroundColor="#FFF59D"></Card>
        <Card content={diplayContent('Google Yearly Elite Valid', googleYearlyEliteValid, true)} width="200px" backgroundColor="#FFF59D"></Card>
      </Cards>
      <Cards>
        <Card content={diplayContent('Apple Monthly Elite Expired', appleMonthlyEliteExpired, false)} width="200px" backgroundColor="#FFF59D"></Card>
        <Card content={diplayContent('Apple Yearly Elite Expired', appleYearlyEliteExpired, false)} width="200px" backgroundColor="#FFF59D"></Card>
        <Card content={diplayContent('Google Monthly Elite Expired', googleMonthlyEliteExpired, false)} width="200px" backgroundColor="#FFF59D"></Card>
        <Card content={diplayContent('Google Yearly Elite Expired', googleYearlyEliteExpired, false)} width="200px" backgroundColor="#FFF59D"></Card>
      </Cards>
      <Cards>
        <Card content={diplayContent('Apple Monthly Valid', appleMonthlyValid, true)} width="200px"></Card>
        <Card content={diplayContent('Apple Yearly Valid', appleYearlyValid, true)} width="200px"></Card>
        <Card content={diplayContent('Google Monthly Valid', googleMonthlyValid, true)} width="200px"></Card>
        <Card content={diplayContent('Google Yearly Valid', googleYearlyValid, true)} width="200px"></Card>
      </Cards>
      <Cards>
        <Card content={diplayContent('Apple Monthly Expired', appleMonthlyExpired, false)} width="200px"></Card>
        <Card content={diplayContent('Apple Yearly Expired', appleYearlyExpired, false)} width="200px"></Card>
        <Card content={diplayContent('Google Monthly Expired', googleMonthlyExpired, false)} width="200px"></Card>
        <Card content={diplayContent('Google Yearly Expired', googleYearlyExpired, false)} width="200px"></Card>
      </Cards>
      <Cards>
        <Card content={diplayContent(`Daily reminders ran: ${notificationsLastTime === null ? '--' : dateTimeHelper.format(notificationsLastTime!, 'yyyy-MM-DD HH:mm')}`, notifications)} width="200px"></Card>
        <Card content={diplayContent(`Montly saving reminders ran: ${monthlySavingNotificationsLastTime === null ? '--' : dateTimeHelper.format(monthlySavingNotificationsLastTime!, 'yyyy-MM-DD HH:mm')}`, monthlySavingNotifications)} width="200px"></Card>
        <Card content={diplayContent(`Paycheck reminders ran: ${paycheckNotificationsLastTime === null ? '--' : dateTimeHelper.format(paycheckNotificationsLastTime!, 'yyyy-MM-DD HH:mm')}`, paycheckNotifications)} width="200px"></Card>
        <Card content={diplayContent(`Inactivity reminders ran: ${inactivityNotificationsLastTime === null ? '--' : dateTimeHelper.format(inactivityNotificationsLastTime!, 'yyyy-MM-DD HH:mm')}`, inactivityNotifications)} width="200px"></Card>
      </Cards>
      <Cards>
        <Card content={diplayContent('Plaid Webhooks', plaidWebhoos)} width="200px"></Card>
        <Card content={diplayContent('Closed Teams', closedTeams)} width="200px"></Card>
        <Card content={diplayContent(`Recurring ran: ${recurringTransactionsLastTime === null ? '--' : dateTimeHelper.format(recurringTransactionsLastTime!, 'yyyy-MM-DD HH:mm')}`, recurringTransactions)} width="200px"></Card>
      </Cards>
      </div>
    </main>
  );
}