import styled, { createGlobalStyle } from 'styled-components';
import ColorHelper from './helpers/color-helper';
import MizaConstants from './support/miza-constants';

export const MainContainer = styled.div`
  width: 100%;
  padding: 20px;
  overflow: auto;
`;

export const MizaBaseStyles = createGlobalStyle`
  html,
  body,
  #m-root {
    height: 100%;
    overflow: hidden;
  }

  body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: ${MizaConstants.Color.TYPEFACE_6};
  }

  .n-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .n-bottom-spacing {
    margin-bottom: 20px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: ${MizaConstants.BORDER_RADIUS};
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background: ${MizaConstants.Color.SURFACE_4};
    border-radius: ${MizaConstants.BORDER_RADIUS};
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background: ${MizaConstants.Color.SURFACE_4};
    border-radius: ${MizaConstants.BORDER_RADIUS};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #bdbdbd;
  }
`;

export const SyncfusionStyles = createGlobalStyle`
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: unset;
  }

  .e-input-group:not(.e-filled) .e-input-group-icon::after,
  .e-input-group.e-control-wrapper:not(.e-filled) .e-input-group-icon::after {
    display: none;
  }

  .e-date-range-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
    color: ${MizaConstants.Color.TYPEFACE_4};
  }

  .e-dropdownbase .e-list-item.e-active,
  .e-dropdownbase .e-list-item.e-active.e-hover {
    color: ${MizaConstants.Color.PRIMARY};
  }

  .e-input-group:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
    border-bottom: unset;
  }

  .e-ddl.e-popup .e-content .e-list-item[data-value='-1'] {
    color: ${MizaConstants.Color.PRIMARY};
  }

  .e-spinner-pane .e-spinner-inner .e-spin-material {
    stroke: ${MizaConstants.Color.PRIMARY};
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    border-color: unset;
  }

  .e-btn.e-flat.e-primary,
  .e-css.e-btn.e-flat.e-primary {
      color: ${MizaConstants.Color.PRIMARY};
      background: none;

      &:hover {
        background: none;
        color: ${MizaConstants.Color.ACCENT_1};
      }
  }

  .e-btn.e-flat,
  .e-css.e-btn.e-flat {
    color: ${MizaConstants.Color.TYPEFACE_5};

    &:hover {
      background: none;
      color: ${MizaConstants.Color.TYPEFACE_6};
    }
  }

  .e-ddl.e-popup {
    border-radius: 5px;
    box-shadow: ${MizaConstants.Color.BOX_SHADOW_12};
    border: 1px solid ${MizaConstants.Color.SURFACE_4};
    overflow: hidden;
  }
  
  .e-input-group.e-search .e-input::selection,
  .e-input-group.e-control-wrapper input.e-input::selection {
    background: ${MizaConstants.Color.PRIMARY};
  }

  .e-datepicker.e-popup-wrapper {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: ${MizaConstants.Color.BOX_SHADOW_16};
  }

  .e-daterangepicker.e-popup .e-presets .e-list-item.e-active {
    color: ${MizaConstants.Color.PRIMARY};
  }

  .n-daterange-picker-container .e-input-group:not(.e-filled) .e-input-group-icon::after,
  .e-input-group.e-control-wrapper:not(.e-filled) .e-input-group-icon::after {
    display: none;
  }
  
  .e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover {
    background-color: ${ColorHelper.applyOpacity(MizaConstants.Color.PRIMARY, 0.3)};
  }
  
  .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day,
  .e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
  .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today span.e-day {
    background-color: ${MizaConstants.Color.PRIMARY};
    color: ${MizaConstants.Color.TYPEFACE_1};
    border: none;
    border-radius: 50%;
  }

  .e-calendar .e-content td.e-selected:hover span.e-day,
  .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
  .e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
  .e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
  .e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day {
    background-color: ${MizaConstants.Color.ACCENT_1};
  }

  .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
  .e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
  .e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
  .e-calendar .e-content td.e-selected.e-focused-date span.e-day,
  .e-calendar .e-content td.e-selected span.e-day,
  .e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day,
  .e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
    background-color: ${MizaConstants.Color.PRIMARY};
  }
  
  .e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover span {
    background-color: transparent;
  }

  .e-daterangepicker.e-popup .e-calendar .e-content .e-weekend:not(.e-selected) span {
    color: ${MizaConstants.Color.PRIMARY};
  }

  .e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span {
    border: none;
    color: ${MizaConstants.Color.PRIMARY};
  }
  
  .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day, 
  .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day, 
  .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day, 
  .e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day, 
  .e-bigger.e-small .e-calendar .e-content td.e-today span.e-day, 
  .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day, 
  .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day, 
  .e-calendar .e-content td.e-focused-date.e-today span.e-day, 
  .e-calendar .e-content td.e-today:hover span.e-day, 
  .e-calendar .e-content td.e-today span.e-day {
    border: 1px solid ${MizaConstants.Color.PRIMARY};
    color: ${MizaConstants.Color.PRIMARY};
  }
  
  .e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day, 
  .e-calendar .e-content td.e-today.e-selected span.e-day,  
  .e-calendar .e-content td.e-today.e-selected.e-focused-date span.e-day {
    background-color: ${MizaConstants.Color.PRIMARY};
    border: 1px solid ${MizaConstants.Color.PRIMARY};
    color: white;
    &:hover {
      color: white;
    }
  }
  
  .e-control-wrapper .e-input-group-icon.e-range-icon:before, .n-daterange-picker-container .e-input-group-icon.e-range-icon:before {
    content: "\\e322";
  }
`;
