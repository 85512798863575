import React, { MouseEvent } from 'react';
import { CardContainer } from './Card.styled';

type CardProps = {
  content: React.ReactNode;
  borderLeftColor?: string;
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  backgroundColor?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
};

const Card = (props: CardProps) => {
  const { width, height, minWidth, minHeight, content, borderLeftColor, backgroundColor, onClick } = props;

  return (
    <CardContainer
      onClick={onClick}
      width={width}
      height={height}
      minWidth={minWidth}
      minHeight={minHeight}
      borderLeftColor={borderLeftColor}
      backgroundColor={backgroundColor}
      data-testid="card-container"
    >
      {content}
    </CardContainer>
  );
};

export default Card;
