import React, { MouseEvent } from 'react';
import MizaEnums from '../../support/miza-enums';
import { ButtonComponent } from './Button.styled';

type ButtonType = typeof MizaEnums.ButtonType[keyof typeof MizaEnums.ButtonType];

type ButtonProps = {
  type?: ButtonType;
  text: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  width?: string;
  height?: string;
  dataTestId?: string;
};

const Button = (props: ButtonProps) => {
  const { type = MizaEnums.ButtonType.Primary, text, disabled = false, onClick, width, height, dataTestId } = props;

  return (
    <ButtonComponent
      variant={type}
      onClick={onClick}
      width={width}
      height={height}
      disabled={disabled}
      data-testid={dataTestId ?? 'button-component'}
    >
      {text}
    </ButtonComponent>
  );
};

export default Button;
