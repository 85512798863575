const MizaConstants = {
    DEFAULT_PAGE_SIZE: 15,

    BORDER_RADIUS: '5px',

    Color: {
        // Primary
        PRIMARY: '#388E3C',
        NEGATIVE: "#F44336",
        POSITIVE: "#4CAF50",
        
        // Accent
        ACCENT_1: '#4FBFD2',
        ACCENT_2: '#7CE0D3',
        ACCENT_3: '#FFB990',
        ACCENT_4: '#F4364C',
        ACCENT_5: '#FFB300',
        ACCENT_6: '#81C784',
        ACCENT_7: '#4FC3F7',
    
        // Surface
        SURFACE_1: '#FFFFFF',
        SURFACE_2: '#FAFAFA',
        SURFACE_3: '#F5F5F5',
        SURFACE_4: '#DBDBDB',
        SURFACE_5: '#212121',
    
        // Typeface
        TYPEFACE_1: '#FCFCFC',
        TYPEFACE_2: '#CCCCCC',
        TYPEFACE_3: '#888888',
        TYPEFACE_4: '#666666',
        TYPEFACE_5: '#444444',
        TYPEFACE_6: '#111111',
    
        // Box-shadow
        BOX_SHADOW_4: '0px 3px 6px #2121210A', // Opacity 0.04
        BOX_SHADOW_8: '0px 3px 6px #21212114', // Opacity 0.08
        BOX_SHADOW_12: '0px 3px 6px #2121211F', // Opacity 0.12
        BOX_SHADOW_16: '0px 3px 6px #21212129', // Opacity 0.16
      },      
      DateFormat: {
        SYNCFUSION_DEFAULT: 'dd/MM/yyyy',
        MOMENT_DEFAULT: 'DD/MM/YYYY',
        SYNCFUSION_ISO: 'yyyy-MM-dd',
        ISO_DATE_FORMAT: 'YYYY-MM-DD',
      },
      DateTimeFormat: {
        SYNCFUSION_ISO: 'yyyy-MM-dd (HH:mm)',
      },
      IconSize: {
        SMALL: 16,
        REGULAR: 24,
      },    
    }  

export default MizaConstants;