import styled from 'styled-components';
import ColorHelper from '../../helpers/color-helper';
import MizaConstants from '../../support/miza-constants';

export const CheckboxContainer = styled.div`
  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check,
  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check,
  .e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check,
  .e-checkbox-wrapper:hover .e-frame.e-check,
  .e-css.e-checkbox-wrapper:hover .e-frame.e-check .e-css.e-checkbox-wrapper:hover .e-frame.e-check {
    background-color: ${MizaConstants.Color.SURFACE_1};
    border-color: ${MizaConstants.Color.PRIMARY};
    color: ${MizaConstants.Color.PRIMARY};
  }

  .e-checkbox-wrapper .e-frame,
  .e-css.e-checkbox-wrapper .e-frame {
    background-color: ${MizaConstants.Color.SURFACE_1};
    border-color: ${MizaConstants.Color.TYPEFACE_4};
  }

  .e-checkbox-wrapper .e-frame:hover,
  .e-css.e-checkbox-wrapper .e-frame:hover {
    border-color: ${MizaConstants.Color.TYPEFACE_5};
  }

  .e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check {
    background-color: ${MizaConstants.Color.SURFACE_1};
    border-color: ${MizaConstants.Color.PRIMARY};
    color: ${MizaConstants.Color.PRIMARY};
  }

  .e-checkbox-wrapper.e-checkbox-disabled,
  .e-css.e-checkbox-wrapper.e-checkbox-disabled {
    color: ${ColorHelper.applyOpacity(MizaConstants.Color.TYPEFACE_4, 0.2)};
    border-color: ${ColorHelper.applyOpacity(MizaConstants.Color.TYPEFACE_4, 0.2)};
  }

  .e-checkbox-wrapper .e-checkbox:active + .e-frame.e-check {
    background-color: ${MizaConstants.Color.SURFACE_1};
    border-color: ${MizaConstants.Color.PRIMARY};
    color: ${MizaConstants.Color.PRIMARY};
  }
`;
