import styled from 'styled-components';
import MizaConstants from '../../support/miza-constants';

type TextFieldContainerProps = {
  disabled: boolean;
  error?: string;
};

type TextFieldInputProps = {
  disabled: boolean;
};

export const TextFieldContainer = styled.div<TextFieldContainerProps>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: ${(props) => (props.disabled ? MizaConstants.Color.SURFACE_2 : MizaConstants.Color.SURFACE_1)};
  border-radius: ${MizaConstants.BORDER_RADIUS};
  border: 1px solid ${(props) => (props.error ? MizaConstants.Color.ACCENT_4 : MizaConstants.Color.SURFACE_4)};
  padding: 0 10px;

  &:hover {
    box-shadow: ${(props) => (props.disabled ? null : MizaConstants.Color.BOX_SHADOW_8)};
  }
`;

export const TextFieldInput = styled.input<TextFieldInputProps>`
  flex-grow: 1;
  color: ${(props) => (props.disabled ? MizaConstants.Color.TYPEFACE_3 : MizaConstants.Color.TYPEFACE_5)};
  background-color: ${(props) => (props.disabled ? MizaConstants.Color.SURFACE_2 : MizaConstants.Color.SURFACE_1)};
  min-height: 32px;
  font-size: 12px;
  border: none;
  outline: none;

  &::placeholder {
    color: ${MizaConstants.Color.TYPEFACE_4};
  }

  &:-webkit-autofill {
    -webkit-background-clip: text;
  }
`;
