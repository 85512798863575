import React, { useCallback } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import GridAccountPage from '../pages/grid-account/GridAccountPage';
import LoginPage from '../pages/authentication/Login';
import MainNavigation from '../pages/common/MainNavigation';
import HomePage from '../pages/home/HomePage';
import GridTransactionPage from '../pages/grid-transaction/GridTransactionPage';

type MizaRoutesProps = {
  isLogged: boolean;
};

const LoggedInLayoutContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LoggedInLayout = () => {
  return (
    <LoggedInLayoutContainer>
      <MainNavigation />
      <Outlet />
    </LoggedInLayoutContainer>
  );
};


const MizaRoutes = (props: MizaRoutesProps) => {
  const { isLogged } = props;
  let location = useLocation();

  const protectRouteElement = useCallback(
    (element: JSX.Element) => {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      if (isLogged) {
        return element;
      }
      return <Navigate to="/login" state={{ from: location }} />;
    },
    [isLogged, location]
  );
  
  return (
    <Routes>
      <Route path="login" element={isLogged ? <Navigate to={location.pathname != '/login' ? location.pathname : '/home'} replace /> : <LoginPage />} />
      <Route element={protectRouteElement(<LoggedInLayout />)}>
        <Route path="/home" element={<HomePage />} />
        <Route path="grid_account" element={<GridAccountPage />} />
        <Route path="grid_transaction" element={<GridTransactionPage />} />
      </Route>
      <Route path="*" element={<Navigate to={isLogged ? '/home' : '/login'} />} />
    </Routes>
  );
};

export default MizaRoutes;
