import React from 'react';
import { LoadingRectangle, LoadingCircle } from './Loading.styled';
import MizaEnums from '../../support/miza-enums';

type LoadingProps = {
  format?: MizaEnums.LoadingFormat;
  width: number;
  height: number;
};

const Loading = (props: LoadingProps) => {
  const { format = MizaEnums.LoadingFormat.Rectangle, width, height } = props;

  return (
    <>
      {format === MizaEnums.LoadingFormat.Rectangle && <LoadingRectangle width={width} height={height} />}
      {format === MizaEnums.LoadingFormat.Circle && <LoadingCircle width={width} height={height} />}
    </>
  );
};

export default Loading;
