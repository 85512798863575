import APIRequestHelper from '../helpers/api-request-helper';

const AuthenticationService = {
  async login(email: string, password: string) {
    const response = await APIRequestHelper.login(email, password);

    if (response.isSuccess && response.data?.['is_system'] && response.data?.['token']) {
      localStorage.setItem('token', response.data['token']);
      localStorage.setItem('email', email);
      localStorage.setItem('teamId', response.data['team_id']);

      return response.data;
    }

    AuthenticationService.logout();

    return null;
  },

  logout(): boolean {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('teamId');

    return true;
  },

  isLogged(): boolean {
    const token = localStorage.getItem('token');
    return token !== null && token !== '' && token !== undefined;
  },

  getLoggedInTeamId(): null | number {
    const teamId = localStorage.getItem('teamId');
    return teamId ? parseInt(teamId) : null;
  },
};

export default AuthenticationService;
