import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../components';
import MizaConstants from '../../support/miza-constants';

export const MainNavigationLogout = styled(Icon)`
  padding: 10px 30px;
  transform: rotate(180deg);

  &:hover {
    background: ${MizaConstants.Color.SURFACE_3};
  }
`;

export const MainNavigationLinkIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledLink = styled(NavLink)<{ $isActive: boolean }>`
  text-decoration: none;

  div {
    margin: 10px 0;
    padding: 10px 30px;
    color: ${(props: any) => (props.$isActive ? MizaConstants.Color.PRIMARY : null)};

    &:hover {
      transition: all 280ms ease;
      background: ${MizaConstants.Color.SURFACE_3};
    }
  }
`;

export const MainNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  text-align: center;
  box-shadow: ${MizaConstants.Color.BOX_SHADOW_4};
  padding: 30px 0 50px 0;
  flex-shrink: 0;
`;

export const MainNavigationLogo = styled.img`
  width: 36px;
  height: 36px;
  margin-bottom: 20px;
`;

export const MainNavigationSeparator = styled.div`
  height: 2px;
  background-color: ${MizaConstants.Color.SURFACE_4};
  margin: 50px 20px;
`;
