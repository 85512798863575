import styled from 'styled-components';
import MizaConstants from '../../support/miza-constants';

export const Content = styled.div<{$isPositive?: boolean}>`
  display: flex;
  justify-content: center;
  font-size: 50px;
  color: ${(props: any) => (props.$isPositive === undefined ?  MizaConstants.Color.TYPEFACE_5 : (props.$isPositive ? MizaConstants.Color.POSITIVE : MizaConstants.Color.NEGATIVE))};
  font-weight: 500;
`;

export const Title = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  color: ${MizaConstants.Color.TYPEFACE_3};
  justify-content: center;
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  row-gap: 20px;
  column-gap: 10px;
  padding-top: 10px;
`;