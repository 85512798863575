import { MouseEvent } from 'react';
import styled from 'styled-components';
import MizaConstants from '../../support/miza-constants';

type CardContainerProps = {
  borderLeftColor?: string;
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  backgroundColor?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
};

const shouldHover = `&:hover {
  cursor: pointer;
  box-shadow: ${MizaConstants.Color.BOX_SHADOW_8};
}`;

export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid ${MizaConstants.Color.SURFACE_4};
  border-radius: ${MizaConstants.BORDER_RADIUS};
  box-shadow: ${MizaConstants.Color.BOX_SHADOW_4};
  box-sizing: border-box;
  ${(props: CardContainerProps) => props.backgroundColor && 'background-color:' + props.backgroundColor};
  ${(props: CardContainerProps) => props.borderLeftColor && 'border-left: 5px solid' + props.borderLeftColor};
  ${(props: CardContainerProps) => props.width && 'width: ' + props.width};
  ${(props: CardContainerProps) => props.height && 'height: ' + props.height};
  ${(props: CardContainerProps) => props.minWidth && 'min-width: ' + props.minWidth};
  ${(props: CardContainerProps) => props.minHeight && 'min-height:' + props.minHeight};
  ${(props: CardContainerProps) => props.onClick && shouldHover};
`;
