import React, { useState, KeyboardEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TextField, Button } from '../../components';
import ToastHelper from '../../helpers/toast-helper';
import { metadataActions } from '../../store/metadata/metadata-actions';
import logo from '../../assets/images/miza_logo.png';
import { LoginContainer, Logo, WelcomeMessage, PasswordTemplate } from './Login.styled';

type LoginProps = {
  login: (email: string, password: string, successCallback?: Function, errorCallback?: Function) => void;
};

const LoginPage = (props: LoginProps) => {
  const { login } = props;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  const [passwordRightTemplate, setPasswordRightTemplate] = useState<string>('Show');
  const [passwordTextFieldType, setPasswordTextFieldType] = useState<string>('password');
  const [wrongCredentialsToastId, setWrongCredentialsToastId] = useState<string>('');

  const changePasswordTextFieldType = () => {
    setPasswordRightTemplate(passwordRightTemplate === 'Show' ? 'Hide' : 'Show');
    setPasswordTextFieldType(passwordRightTemplate === 'Show' ? 'text' : 'password');
  };

  const onEmailChange = (value: string) => {
    setEmail(value);
    setEmailErrorMessage('');
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);
    setPasswordErrorMessage('');
  };

  const handleLogin = async () => {
    if (wrongCredentialsToastId) {
      ToastHelper.removeToastMessage(wrongCredentialsToastId);
    }

    if (!email) {
      setEmailErrorMessage('The email address is required');
      return;
    }

    if (!password) {
      setPasswordErrorMessage('The password is required');
      return;
    }

    const errorCallback = () => {
      setWrongCredentialsToastId(ToastHelper.addToastMessage({ message: 'The email or password provided is incorrect' }));
    };

    login(email, password, undefined, errorCallback);
  };

  const onLoginClick = () => {
    handleLogin();
  };

  const onKeypress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <LoginContainer>
      <Logo src={logo} />
      <WelcomeMessage>Welcome Abex users</WelcomeMessage>

      <TextField
        type="email"
        label="Email"
        required={true}
        placeholder="Enter email"
        value={email}
        autoFocus={true}
        onChange={onEmailChange}
        onKeyPress={onKeypress}
        className="n-bottom-spacing"
        error={emailErrorMessage}
      />
      <TextField
        type={passwordTextFieldType}
        label="Password"
        required={true}
        placeholder="Enter password"
        onChange={onPasswordChange}
        onKeyPress={onKeypress}
        value={password}
        className="n-bottom-spacing"
        rightTemplate={<PasswordTemplate onClick={changePasswordTextFieldType}>{passwordRightTemplate}</PasswordTemplate>}
        error={passwordErrorMessage}
      />

      <Button text="Sign in" onClick={onLoginClick} width="100%" height="35px" dataTestId="signin-button" />
    </LoginContainer>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      login: metadataActions.login,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(LoginPage);
