import { MouseEvent } from 'react';
import styled from 'styled-components';
import MizaConstants from '../../support/miza-constants';

type IconSize = typeof MizaConstants.IconSize[keyof typeof MizaConstants.IconSize];

type IconContainerProps = {
  disabled: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  size: IconSize;
  showHoverEffect: boolean;
  color?: string;
  hoverColor?: string;
};

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props: IconContainerProps) => (props.showHoverEffect ? '10px' : null)};
  color: ${(props: IconContainerProps) => (props.disabled ? MizaConstants.Color.TYPEFACE_2 : props.color)};
  font-size: ${(props: IconContainerProps) => props.size + 'px'};
  border-radius: ${(props: IconContainerProps) => (props.showHoverEffect ? '50%' : null)};
  transition: ${(props: IconContainerProps) => (props.onClick && !props.disabled ? 'background-color 280ms ease' : null)};
  cursor: ${(props: IconContainerProps) => (props.onClick && !props.disabled ? 'pointer' : 'default')};
  user-select: none;

  &:hover {
    color: ${(props: IconContainerProps) => (props.disabled ? MizaConstants.Color.TYPEFACE_2 : props.hoverColor)};
    background-color: ${(props: IconContainerProps) => (props.showHoverEffect ? MizaConstants.Color.SURFACE_3 : null)};
  }
`;
