import { DataManager, ODataAdaptor, ReturnOption, UrlAdaptor } from '@syncfusion/ej2-data';
//const NUTCACHE_COMPANY_GUID = 'd8abe067-1cb7-4257-9274-358fa2c3c83e';
const BASE_URI = "https://api.timeforbudget.com/api"; // Live 
//const BASE_URI = "https://127.0.0.1:44345/api"; //Debug  
const httpStatus = {
  OK: 200,
  Created: 201,
  Accepted: 202,
  NoContent: 204,
};

export interface APIResponse {
  data: any;
  isSuccess: boolean;
  statusCode: number;
}

const APIRequestHelper = {
  async sendGetRequest(action: string) {
    const token = localStorage.getItem('token');

    if (token !== '') {
      const url = `${BASE_URI}/${action}`;
      const fetchParameters = {
        method: 'GET',
        headers: {
          'content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${token}`,
        },
      };

      const result = await fetch(url, fetchParameters);
      const response = await result.json();
      return {
        data: response,
        StatusCode: result.status,
        isSuccess:
          result.status === httpStatus.OK ||
          result.status === httpStatus.Created ||
          result.status === httpStatus.Accepted ||
          result.status === httpStatus.NoContent,
      };
    }
    return {
      data: '',
      isSuccess: false,
    };
  },

  async sendRequest(httpMethod: string, action: string, payload?: string | {} | null) {
    const token = localStorage.getItem('token');
    const teamId = localStorage.getItem('teamId');

    if (token !== '') {
      const url = `${BASE_URI}/${action}`;
      const fetchParameters = {
        method: httpMethod,
        headers: {
          'content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${token}`,
          ApiKey: 'c41eea85-3070-46e1-8d6a-0580b1f670a7', 
          team_id: `${teamId}`
        },

        body: JSON.stringify(payload),
      };
      // if (payload) {
      //   fetchParameters.body = JSON.stringify(payload);
      // }
      const result = await fetch(url, fetchParameters);
      const response = await result.json();

      return {
        data: response,
        StatusCode: result.status,
        isSuccess:
          result.status === httpStatus.OK ||
          result.status === httpStatus.Created ||
          result.status === httpStatus.Accepted ||
          result.status === httpStatus.NoContent,
      };
    }
    return {
      data: '',
      isSuccess: false,
    };
  },

  async sendDeleteRequest(action: string) {
    const token = localStorage.getItem('token');

    if (token !== '') {
      const url = `${BASE_URI}/${action}`;
      const fetchParameters = {
        method: 'DELETE',
        headers: {
          'content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${token}`,
        },
      };

      const result = await fetch(url, fetchParameters);
      const response = await result.json();
      return {
        data: response,
        StatusCode: result.status,
        isSuccess:
          result.status === httpStatus.OK ||
          result.status === httpStatus.Created ||
          result.status === httpStatus.Accepted ||
          result.status === httpStatus.NoContent,
      };
    }
    return {
      data: '',
      isSuccess: false,
    };
  },

  async login(username: string, password: string) {
    const result = await fetch(`${BASE_URI}/authenticate/login`, {
      method: 'POST',
      headers: { 
        "content-type": "application/json",
        "Accept": "application/json",
        'ApiKey': 'c41eea85-3070-46e1-8d6a-0580b1f670a7' },// TODO: use a different API-KEY?
      body: JSON.stringify({
        "username": username,
        "password": password
      }),
    });

    const response = await result.json();
    let apiResponse: APIResponse = { data: null, statusCode: httpStatus.NoContent, isSuccess: false };

    if (response && response.token) {
      apiResponse = {
        data: response,
        statusCode: result.status,
        isSuccess:
          result.status === httpStatus.OK ||
          result.status === httpStatus.Created ||
          result.status === httpStatus.Accepted ||
          result.status === httpStatus.NoContent,
      };
    }
    return apiResponse;
  },
  async getDataManager(httpMethod: string, action: string, offline?: boolean) {
    const token = localStorage.getItem('token');
    const teamId = localStorage.getItem('teamId');
    const url = `${BASE_URI}/${action}`;
    const headers = [{ 
      "content-type": "application/json",
      "Accept": "application/json",
      Authorization: `Bearer ${token}`, 
      ApiKey: 'c41eea85-3070-46e1-8d6a-0580b1f670a7', 
      team_id: teamId }];
    try {
      return new DataManager({
        requestType: httpMethod,
        url,
        adaptor: new UrlAdaptor(),
        crossDomain: true,
        offline: false,
        headers: headers,
      });
    }
    catch (error) {
      console.log(error);
    }
  },
};

export default APIRequestHelper;
