import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import { ToastMessageQueueContainer, ToastMessageContainer } from './ToastMessageQueue.styled';

type ToastMessagePayload = {
  message?: string;
  template?: (...args: any[]) => ReactNode;
  duration?: number;
  data?: object;
};

type ToastMessage = ToastMessagePayload & { id: string };

const PORTAL_ELEMENT_ID = 'n-toast-messages';
const ADD_TOAST_MESSAGE_EVENT = 'n-add-toast-message';
const REMOVE_TOAST_MESSAGE_EVENT = 'n-remove-toast-message';
const defaultToastMessageProps = { duration: 5000 };

export const addToastMessage = (toastMessage: ToastMessagePayload) => {
  const id = uuidv4();
  const addToastMessageEvent = new CustomEvent(ADD_TOAST_MESSAGE_EVENT, { detail: { id, ...toastMessage } });

  document.dispatchEvent(addToastMessageEvent);

  return id;
};

export const removeToastMessage = (id: string) => {
  const removeToastMessageEvent = new CustomEvent(REMOVE_TOAST_MESSAGE_EVENT, { detail: { id } });

  document.dispatchEvent(removeToastMessageEvent);
};

const ToastMessageQueue = () => {
  const [isPortalReady, setIsPortalReady] = useState<boolean>(false);
  const [activeToastMessage, setActiveToastMessage] = useState<ToastMessage | null>(null);
  const toastMessageQueueRef = useRef<ToastMessage[]>([]);
  const activeTimerRef = useRef<any>(null);

  const updateActiveToastMessage = () => {
    // Clear the active timer if the toast message has been removed from the queue
    if (
      activeTimerRef.current &&
      !toastMessageQueueRef.current.find((toastMessage) => toastMessage.id === activeTimerRef.current.toastMessageId)
    ) {
      clearTimeout(activeTimerRef.current.timeoutId);
      activeTimerRef.current = null;
    }

    // Currently, we only want to display one toast at a time, so if there is an active current timer, we abort
    if (activeTimerRef.current) {
      return;
    }

    // Take the next toast messsage from the queue
    const nextToastMessage = toastMessageQueueRef.current[0];

    if (nextToastMessage) {
      setActiveToastMessage(nextToastMessage);

      const timeoutId = setTimeout(() => {
        removeToastMessage(nextToastMessage.id);
      }, nextToastMessage.duration);

      activeTimerRef.current = { toastMessageId: nextToastMessage.id, timeoutId };
    } else {
      setActiveToastMessage(null);
    }
  };

  useEffect(() => {
    const onAddToastMessage = (e: CustomEvent) => {
      const data = { ...defaultToastMessageProps, ...e?.detail };

      if (!toastMessageQueueRef.current.find((toastMessage) => toastMessage.id === data.id)) {
        toastMessageQueueRef.current.push(data);
        updateActiveToastMessage();
      }
    };

    const onRemoveToastMessage = (e: CustomEvent) => {
      const toastMessageId = e.detail?.id;
      const toastMessageIndex = toastMessageQueueRef.current.findIndex((toastMessage) => toastMessage.id === toastMessageId);

      if (toastMessageIndex >= 0) {
        toastMessageQueueRef.current.splice(toastMessageIndex, 1);
        updateActiveToastMessage();
      }
    };

    if (!document.getElementById(PORTAL_ELEMENT_ID)) {
      const portalElement = document.createElement('div');
      portalElement.id = PORTAL_ELEMENT_ID;
      document.body.appendChild(portalElement);
    }

    document.addEventListener(ADD_TOAST_MESSAGE_EVENT, onAddToastMessage as EventListener);
    document.addEventListener(REMOVE_TOAST_MESSAGE_EVENT, onRemoveToastMessage as EventListener);

    setIsPortalReady(true);

    return () => {
      document.removeEventListener(ADD_TOAST_MESSAGE_EVENT, onAddToastMessage as EventListener);
      document.removeEventListener(REMOVE_TOAST_MESSAGE_EVENT, onRemoveToastMessage as EventListener);
    };
  }, []);

  return (
    <>
      {isPortalReady &&
        activeToastMessage &&
        ReactDOM.createPortal(
          <ToastMessageQueueContainer>
            <ToastMessageContainer data-testid={`toast-message-${activeToastMessage.id}`}>
              {activeToastMessage.template ? activeToastMessage.template(activeToastMessage) : activeToastMessage.message}
            </ToastMessageContainer>
          </ToastMessageQueueContainer>,
          document.getElementById(PORTAL_ELEMENT_ID) as HTMLElement
        )}
    </>
  );
};

export default ToastMessageQueue;
